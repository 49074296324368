.content {
	grid-area: content;
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: clamp(5rem, 10vw, 6.875rem);

	.h2 {
		font-size: 3rem;
		text-align: center;
	}
}