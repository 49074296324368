.hero {
	grid-area: hero;

	display: grid;
	grid-template-areas:
		"icon"
		"hero-content";
	gap: 6rem;
	grid-template-columns: 1fr;

	@media (min-width: $xlMax) {
		grid-template-areas:
			"icon hero-content";
		grid-template-columns: 1fr, max-content;
	}

	.icon {
		--padding: 2.5rem;
		grid-area: icon;
		display: flex;
		align-items: center;
		justify-content: center;

		display: flex;
		justify-self: center;

		background-color: var(--black);
		border-radius: var(--padding);
		padding: var(--padding);
		width: clamp(1rem, 75%, 15rem);
		aspect-ratio: 1;

		box-shadow: 0 3rem 5rem -2.5rem var(--shadow-color);

		@media (min-width: $xlMax) {
			width: 15rem;
		}

		img {
			width: 100%;
		}
	}

	.hero-content {
		grid-area: hero-content;
		font-size: 1.25rem;
		text-align: center;

		@media (min-width: $xlMax) {
			text-align: start;
		}

		.headline {
			grid-area: headline;
			font-size: clamp(3rem, 10vw, 4.75rem);
			margin-bottom: 3.125rem;
			line-height: 1;
			font-family: monospace;
		}

		.caption {
			font-size: 0.9rem;
			color: var(--color2);
		}

		.downloadButton {
			margin: 2rem auto 0;

			@media (min-width: $xlMax) {
				margin: 2rem 0 0;
			}
		}
	}
}