:root {
  --primary: #38dab0;
  --white: #fff;
  --black: #0c0c0c;
  --background-color: #e8e8e8;
  --background-color-gradient: #fff;
  --foreground-color: #1c1c1c;
  --color: #494949;
  --color2: #9b9b9b;
  --shadow-color: #0c0c0c;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #38dab0;
    --white: #fff;
    --black: #0c0c0c;
    --background-color: #1c1c1c;
    --background-color-gradient: #0c0c0c;
    --foreground-color: #e8e8e8;
    --color: #e8e8e8;
    --color2: #9b9b9b;
    --shadow-color: #38dab055;
  }
}

.blink {
  animation: 1s infinite blink;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  color: var(--color);
  background: linear-gradient(61deg, var(--background-color) 0%, var(--background-color-gradient) 100%);
  justify-content: center;
  align-items: center;
  font-size: 16px;
  display: flex;
}

a {
  color: var(--color2);
}

a:hover, a:focus {
  color: var(--primary);
  text-decoration: underline;
}

.downloadButton {
  width: 250px;
  display: block;
  overflow: hidden;
}

.downloadButton img {
  width: 100%;
}

.card {
  grid-template-columns: max-content 1fr;
  grid-template-areas: "icon headline"
                       "icon paragraph";
  gap: 1rem 2rem;
  display: grid;
  position: relative;
}

.card .icon {
  width: 4rem;
  grid-area: icon;
}

.card .icon img {
  width: 100%;
}

.card .card-headline {
  grid-area: headline;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.card p {
  grid-area: paragraph;
  margin-top: 0;
}

.changelog .changelog-headline {
  margin-bottom: 3rem;
}

.changelog > ul {
  grid-template-columns: 1fr;
  gap: 2rem;
  padding-inline-start: 0;
  list-style: none;
  display: grid;
}

.changelog > ul li .h3 {
  margin-block-end: .1em;
}

.content {
  width: 100%;
  flex-direction: column;
  grid-area: content;
  gap: clamp(5rem, 10vw, 6.875rem);
  display: flex;
}

.content .h2 {
  text-align: center;
  font-size: 3rem;
}

.download .download-headline {
  margin-bottom: 3rem;
}

.download .downloadButton {
  margin: 2rem auto 0;
}

.features .headline {
  display: none;
}

.features ul {
  grid-template-columns: 1fr;
  align-items: start;
  gap: 2rem 4rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
}

@media (min-width: 992px) {
  .features ul {
    grid-template-columns: 1fr 1fr;
  }
}

.features .icon svg {
  width: 4.5rem;
  fill: var(--color);
}

footer {
  flex-direction: row;
  grid-area: footer;
  justify-content: space-between;
  align-items: end;
  font-size: .8rem;
  display: flex;
}

footer ul {
  flex-direction: column;
  gap: .25rem 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

@media (min-width: 768px) {
  footer ul {
    flex-direction: row;
  }
}

footer ul li a {
  color: #494949;
}

footer .logo {
  width: 3rem;
  height: 3rem;
  fill: var(--foreground-color);
}

.grid {
  max-width: 1400px;
  grid-template-areas: "hero"
                       "content"
                       "footer";
  gap: 6rem;
  margin: clamp(4rem, 15%, 10rem);
  display: grid;
}

.hero {
  grid-area: hero;
  grid-template-columns: 1fr;
  grid-template-areas: "icon"
                       "hero-content";
  gap: 6rem;
  display: grid;
}

@media (min-width: 1200px) {
  .hero {
    grid-template-areas: "icon hero-content";
    grid-template-columns: 1fr, max-content;
  }
}

.hero .icon {
  --padding: 2.5rem;
  background-color: var(--black);
  border-radius: var(--padding);
  padding: var(--padding);
  width: clamp(1rem, 75%, 15rem);
  aspect-ratio: 1;
  box-shadow: 0 3rem 5rem -2.5rem var(--shadow-color);
  grid-area: icon;
  justify-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1200px) {
  .hero .icon {
    width: 15rem;
  }
}

.hero .icon img {
  width: 100%;
}

.hero .hero-content {
  text-align: center;
  grid-area: hero-content;
  font-size: 1.25rem;
}

@media (min-width: 1200px) {
  .hero .hero-content {
    text-align: start;
  }
}

.hero .hero-content .headline {
  grid-area: headline;
  margin-bottom: 3.125rem;
  font-family: monospace;
  font-size: clamp(3rem, 10vw, 4.75rem);
  line-height: 1;
}

.hero .hero-content .caption {
  color: var(--color2);
  font-size: .9rem;
}

.hero .hero-content .downloadButton {
  margin: 2rem auto 0;
}

@media (min-width: 1200px) {
  .hero .hero-content .downloadButton {
    margin: 2rem 0 0;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1;
}

p {
  font-size: 1.25rem;
}

.teaserVideo video {
  width: 100%;
}

/*# sourceMappingURL=index.6398d1bf.css.map */
