.features {
	.headline {
		display: none;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		display: grid;
		gap: 2rem 4rem;
		align-items: start;
		grid-template-columns: 1fr;

		@media (min-width: $lgMax) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.icon {
		svg {
			width: 4.5rem;
			fill: var(--color);
		}
	}
}