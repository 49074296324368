.grid {
	margin: clamp(4rem, 15%, 10rem);

	display: grid;
	grid-template-areas:
		"hero"
		"content"
		"footer";

	gap: 6rem;
	max-width: $xxlMax;
}