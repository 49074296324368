footer {
	grid-area: footer;
	display: flex;
	flex-direction: row;
	align-items: end;
	justify-content: space-between;
	font-size: 0.8rem;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		display: flex;
		gap: 0.25rem 1rem;
		flex-direction: column;

		@media (min-width: $mdMax) {
			flex-direction: row;
		}

		li {
			a {
				color: #494949;
			}
		}
	}

	.logo {
		width: 3rem;
		height: 3rem;
		fill: var(--foreground-color);
	}
}