.changelog {
	.changelog-headline {
		margin-bottom: 3rem;
	}

	&>ul {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		list-style: none;
		// list-style-type: "# ";
		padding-inline-start: 0;

		li {
			.h3 {
				margin-block-end: 0.1em;
			}
		}
	}

}